exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-coaching-js": () => import("./../../../src/pages/coaching.js" /* webpackChunkName: "component---src-pages-coaching-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-policies-js": () => import("./../../../src/pages/policies.js" /* webpackChunkName: "component---src-pages-policies-js" */),
  "component---src-pages-rates-js": () => import("./../../../src/pages/rates.js" /* webpackChunkName: "component---src-pages-rates-js" */),
  "component---src-pages-subjects-js": () => import("./../../../src/pages/subjects.js" /* webpackChunkName: "component---src-pages-subjects-js" */),
  "component---src-pages-testimonials-js": () => import("./../../../src/pages/testimonials.js" /* webpackChunkName: "component---src-pages-testimonials-js" */),
  "component---src-pages-testprep-js": () => import("./../../../src/pages/testprep.js" /* webpackChunkName: "component---src-pages-testprep-js" */)
}

